"use client";

import Box from "@mui/material/Box";
import { SectionContainer } from "@src/components";
import { makeStyles } from "tss-react/mui";
import { NextImage } from "@src/components";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useResponsive } from "@src/hooks";
import Image from "next/image";
import { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import useDomLoaded from "@src/hooks/use-dom-loaded";
import type { Banner } from "@src/types/home";
import $ from "jquery"; // Import jQuery

const useStyles = makeStyles<{
  height: number;
  isMobile: boolean;
}>()((_, {
  height,
  isMobile
}) => ({
  paginationBullet: {
    margin: isMobile ? "0px 4px" : "0px 10px",
    width: "10px",
    height: "10px",
    border: "1px solid #000",
    textAlign: "center",
    color: "#000",
    opacity: 1,
    background: "#fff",
    borderRadius: "50%",
    cursor: "pointer"
  },
  paginationBulletActive: {
    boxSizing: "border-box",
    background: "black",
    width: "12px",
    height: "12px"
  },
  mySwiper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .swiper-wrapper ": {
      height: isMobile ? `${height}px` : "calc(100vh - 160px)"
    },
    "& .swiper-pagination": {
      top: "0px !important",
      paddingTop: isMobile ? "20px" : "32px",
      alignItems: "center",
      position: "relative"
    }
  }
}));
const BackgroundCover = ({
  banners
}: {
  banners: Banner[];
}) => {
  const isDomLoaded = useDomLoaded();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageHeight, setImageHeight] = useState(null);
  const {
    isMobile
  } = useResponsive();
  const {
    classes
  } = useStyles({
    height: imageHeight,
    isMobile
  });
  useEffect(() => {
    if (document) {
      setTimeout(() => {
        const height = document.querySelector(".banner-height")?.clientHeight;
        setImageHeight(height);
        if (!isMobile) {
          $('.swiper-wrapper').css('height', `${window.screen.width * 5 / 12}px`);
        }
      }, 50);
    }
  }, [imageLoaded]);
  if (!banners || banners.length === 0) {
    return null;
  }
  if (!isDomLoaded) {
    return <SectionContainer sx={{
      maxHeight: "100%",
      maxWidth: true,
      width: "100%",
      p: "0px !important"
    }} maxWidth={true} sxWrapper={{
      background: "transparent",
      margin: {
        xs: "0px 0px"
      }
    }}>
        <Box sx={{
        height: "100vh",
        width: "100%"
      }}>
          {/* <Image fill src={placeholderImage} objectFit="cover" alt="banner" /> */}
        </Box>
      </SectionContainer>;
  }
  if (isMobile) {
    return <SectionContainer sx={{
      maxHeight: `${imageHeight + 32}px`,
      height: `${imageHeight + 32}px`,
      maxWidth: true,
      width: "100%",
      p: "0px !important"
    }} maxWidth={true} sxWrapper={{
      background: "transparent",
      margin: {
        xs: "0px 0px"
      }
    }}>
        {banners?.[0]?.mobile_image && <Image style={{
        width: "100%",
        height: "fit-content",
        objectFit: "cover",
        visibility: "hidden"
      }} width={0} height={0} sizes="100vw" className="banner-height" src={banners?.[0]?.mobile_image} alt="banner" onLoad={() => {
        setImageLoaded(true);
      }} />}
        {imageLoaded ? <Box sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: `${imageHeight}px`,
        "& .swiper-pagination": {
          display: banners?.length > 1 ? "flex !important" : "none !important"
        }
      }}>
            {banners?.length > 0 && <Swiper pagination={{
          clickable: true,
          bulletClass: classes.paginationBullet,
          bulletActiveClass: classes.paginationBulletActive
        }} className={classes.mySwiper} autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }} modules={[Pagination, Autoplay]}>
                {(banners || []).map(item => <SwiperSlide key={item.id}>
                    <Link href={item.url || ""}>
                      <NextImage key={item.id} src={item.mobile_image} alt={item.title || item.description || ""} loading="lazy" sx={{
                "& img": {
                  objectFit: "cover",
                  height: `${imageHeight}px !important`
                }
              }} />
                    </Link>
                  </SwiperSlide>)}
              </Swiper>}
          </Box> : <Box sx={{
        position: "relative",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        flexDirection: "column",
        width: "100%",
        height: "100%"
      }}>
            <Skeleton variant="rectangular" animation="wave" sx={{
          width: "100vw",
          height: "100vh"
        }} />
          </Box>}
      </SectionContainer>;
  }
  return <SectionContainer sx={{
    maxHeight: "100%",
    maxWidth: true,
    width: "100%",
    p: "0px !important"
  }} maxWidth={true} sxWrapper={{
    background: "transparent",
    margin: {
      xs: "0px 0px"
    }
  }} data-sentry-element="SectionContainer" data-sentry-component="BackgroundCover" data-sentry-source-file="background-cover.tsx">
      <Box sx={{
      position: "relative",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      "& .swiper-pagination": {
        display: banners?.length > 1 ? "flex !important" : "none !important"
      }
    }} data-sentry-element="Box" data-sentry-source-file="background-cover.tsx">
        {banners?.length > 0 && <Swiper pagination={{
        clickable: true,
        bulletClass: classes.paginationBullet,
        bulletActiveClass: classes.paginationBulletActive
      }} className={classes.mySwiper} height={imageHeight} autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }} modules={[Pagination, Autoplay]}>
            {(banners || []).map(item => <SwiperSlide key={item.id}>
                <Link href={item.url || ""}>
                  <NextImage key={item.id} src={item.desktop_image} alt={item.title || item.description || ""} className="banner-height" loading="lazy" sx={{
              "& img": {
                objectFit: "cover",
                height: "auto !important"
              }
            }} />
                </Link>
              </SwiperSlide>)}
          </Swiper>}
      </Box>
    </SectionContainer>;
};
export default BackgroundCover;